import CloseIcon from 'components/icon/close-icon';
import SquareWrapper, { SquareIconProps } from '../wrapper';

type Props = SquareIconProps & {className?: string};

export default function SquareCloseIcon ({ onClick, className, size, testId, isHoverable, bgColor = 'dark' }: Props) {
  return (
    <SquareWrapper onClick={onClick} size={size} testId={testId} isHoverable={isHoverable} bgColor={bgColor}>
      <CloseIcon className={className}/>
    </SquareWrapper>
  );
}