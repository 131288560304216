import type { DeprecatedPropertyTypeFilter, Filter, PropertyTypeFilter } from 'contexts/preferences/listing-params/types';

export default function generateSearchDescriptionFromFilters(filter: Filter) {
  const { areaName, bedrooms, homeType } = filter;

  // Convert deprecated property type filter to new property type filter
  const { houseDetached = false, houseSemidetached = false, houseAttached = false } = homeType as PropertyTypeFilter & DeprecatedPropertyTypeFilter;
  if (houseDetached || houseSemidetached || houseAttached) {
    homeType.house = true;
  }

  const simplifiedHomeType = {
    houses: homeType.house,
    condos: homeType.condo,
    townhouses: homeType.townhouse,
    commercial: homeType.commercial,
    land: homeType.land,
    farms: homeType.farm,
  };
  let description = '';

  // Helper function to count the number of true values in homeType
  const countTrueHomeTypes = () => Object.values(simplifiedHomeType).filter(Boolean).length;

  if (areaName) {
    description += areaName;
  }

  if (bedrooms !== '0+') { // If not default (0+), add # bed in description
    description += ` ${bedrooms} bed`;
  }

  if (countTrueHomeTypes() === 1) { // Only one home type is selected
    if (simplifiedHomeType.houses) {
      description += ' houses';
    } else if (simplifiedHomeType.townhouses) {
      description += ' townhouses';
    } else if (simplifiedHomeType.condos) {
      description += ' condos';
    } else if (simplifiedHomeType.commercial) {
      description += ' commercial';
    } else if (simplifiedHomeType.land) {
      description += ' land';
    } else if (simplifiedHomeType.farms) {
      description += ' farms';
    }
  } else if (countTrueHomeTypes() === 2) { // Two home types are selected
    const selectedHomeTypes = Object.entries(simplifiedHomeType)
      .filter(([, selected]) => selected)
      .map(([type]) => type);
    description += ` ${selectedHomeTypes[0]} and ${selectedHomeTypes[1]}`;
  } else {
    description += ' listings';
  }

  return description.trim();
}
