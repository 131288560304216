export function TryScrambleText(textValue: (string | number | undefined | boolean | null), shouldScramble: boolean) {
  if (shouldScramble && (typeof textValue === 'string' || typeof textValue === 'number')) {
    let valueArray: (string | number)[] = [];

    if (typeof textValue === 'string') {
      valueArray = textValue?.toString().split('');
    } else if (typeof textValue === 'number') {
      valueArray = textValue?.toString().split('').map(digit => parseInt(digit));
    }

    let currentIndex = valueArray?.length;

    // While there are remaining elements to shuffle
    while (currentIndex !== 0) {
      // Pick a remaining element
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // Swap it with the current element
      const temp = valueArray[currentIndex];
      valueArray[currentIndex] = valueArray[randomIndex];
      valueArray[randomIndex] = temp;
    }

    if (typeof textValue === 'number') {
      textValue = parseInt(valueArray?.join(''));
    } else {
      textValue = valueArray?.join('');
    }
  }

  return textValue;
}
