import React from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
  children: React.ReactElement;
}

export default function ConditionalWrapper({ condition, wrapper, children } : Props) {
  return (
    condition ? wrapper(children) : children
  );
}
