import React from 'react';
import LoadingSpinner from 'components/loading-spinner';
import EmptyState from 'components/empty-state';

interface Props<T> {
  isLoading: boolean;
  data: T | null;
  emptyState?: string | JSX.Element;
  hiddenWhenEmpty?: boolean;
  children: (data: T) => JSX.Element;
}
export default function LoadingData<T>({ isLoading, data, emptyState, hiddenWhenEmpty, children }: Props<T>) {
  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    if (!data || (Array.isArray(data) && !data.length)) {
      if (hiddenWhenEmpty) {
        return null;
      } else if (typeof emptyState === 'string' || emptyState === undefined) {
        return <EmptyState message={emptyState} />;
      } else {
        return emptyState;
      }
    } else {
      return children(data);
    }
  }
}
