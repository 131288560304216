import { capitalizeWords } from '@zoocasa/node-kit/strings';
import { TryScrambleText } from './text-scrambler';
import { getProvinceOrStateCopy } from './province_or_state';

export default function generateFullAddress(streetAddress: string | null, cityAddress: string | null, provinceAddress: string | null, shouldScramble: boolean) {
  const street = streetAddress ? streetAddress : '';
  const city = cityAddress ? capitalizeWords(cityAddress.toLowerCase()) : '';
  const province = provinceAddress ? getProvinceOrStateCopy(provinceAddress, cityAddress) : '';
  const fullAddress = `${street}${city && ', '+city}${province && ', '+province}`;
  return (
    TryScrambleText(fullAddress, shouldScramble)
  );
}
