import React from 'react';
import WarningIcon from 'components/icon/warning-icon';
import styles from './style.module.scss';

export default function EmptyState({ message = 'No Results' }: { message?: string | JSX.Element }) {
  return (
    <div className={styles.component}>
      <WarningIcon/>
      <div className={styles.message}>
        {message}
      </div>
    </div>
  );
}
