import React, { ReactNode } from 'react';

interface Props {
  itemProp?: string;
  itemType: string;
  children: ReactNode;
}

export default function SchemaOrg({ itemProp, itemType, children }: Props) {
  return <span itemProp={itemProp} itemScope={true} itemType={`https://schema.org/${itemType}`}>{children}</span>;
}
