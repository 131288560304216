import browser from '@zoocasa/node-kit/browser';
import { loadJQuery } from 'utils/load-file';

export default function scrollToSelector(selector: string, offset?: number, scrollElement?: Element, animationDuration = 400) {
  const element = document.querySelector(selector);
  if (element) {
    scrollToElement(element, offset, scrollElement, animationDuration);
  }
}

export function scrollToElement(element: Element, offset = 0, scrollElement?: Element, animationDuration = 400) {
  scrollToTop(element.getBoundingClientRect().top - offset, scrollElement, animationDuration);
}

function scrollToTop(topOffset: number, scrollContainerElement?: Element, animationDuration = 400) {
  loadJQuery().then(() => {
    const $ = window['jQuery' as any] as any;
    if ($) {
      let scrollElement = $(scrollContainerElement);
      if (!scrollElement || !scrollElement[0] || scrollElement[0] === window) {
        scrollElement = $('html');
      }
  
      if (browser.isSafari() && scrollElement[0].tagName === 'HTML') {
        $(window).scrollTop(topOffset); // Safari does not support scrolling on the html element
      } else {
        scrollElement.animate({ scrollTop: topOffset }, animationDuration);
      }
    }
  });
}
