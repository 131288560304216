import { useEffect, useState } from 'react';

interface ISqftTextProps {
  squareFootageLabel: string;
  render: (sqft: string) => JSX.Element;
}
export default function SqftText({ squareFootageLabel, render }: ISqftTextProps) {
  const [sqftLabel, setSqftLabel] = useState('');
  useEffect(() => {
    if (squareFootageLabel) {
      setSqftLabel(squareFootageLabel);
    }
  }, [squareFootageLabel]);
  return render(sqftLabel);
}
